<template>
  <v-menu
    v-model="dialog"
    absolute
    :position-x="posX"
    :position-y="posY"
    :close-on-content-click="false"
  >
    <v-card :ripple="false" elevation-0 min-width="300px" min-height="200px">
      <v-card-title class="pa-0 ma-0">
        <v-spacer />
        {{ getCardTitle(events) }}
        <v-spacer />
      </v-card-title>
      <v-card-text class="pt-5">
        <div
          v-for="(schedule, i) in events"
          :key="i"
          :index="schedule.index"
          class="pb-1"
        >
          <div
            height="100%"
            :style="scheduleStyle(schedule.input.color, schedule.priority)"
            class="pa-0 ma-0 schedule-div"
            @click.stop="clickSchedule(schedule.input.eventCrude)"
          >
            <span
              :style="{ width: wrapWidth, fontSize: font_size }"
              class="d-inline-block text-truncate ml-1 mt-1 mb-0 ma-0"
            >
              {{ schedule.start.time }}
              {{ schedule.input.name }}
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ['userCalendars', 'events', 'posX', 'posY'],

  data: () => ({
    dialog: false,
    clickX: null,
    clickY: null,
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  }),

  computed: {
    wrapWidth() {
      return '250px';
    },

    font_size() {
      return '1.0em';
    },
  },

  methods: {
    getCardTitle(events) {
      if (events.length != 0) {
        return `${events[0].start.date.slice(8, 10)}, ${
          this.months[events[0].start.month - 1]
        }`;
      }
      return '';
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    },

    scheduleStyle(color, priority) {
      const eventColour = color;
      const fgColor = color == '#000000' ? '#FFFFFF' : '#000000';
      const checkPriority = this.checkPriority(priority);

      return `height:30px;backgroundColor:${eventColour};color:${fgColor};border:${checkPriority};border-radius: 8px`;
    },

    clickSchedule(schedule) {
      this.$emit('open-event-details', schedule);
    },

    onClosingDialog(response) {
      this.dialog = false;
      this.$emit('close', response);
    },

    checkPriority(priority) {
      if (priority == 1) return 'solid red 2px';
      return '0';
    },
  },
};
</script>
