<template>
  <v-autocomplete
    ref="selector"
    v-model="calendarOwner"
    item-text="name"
    item-value="uid"
    width="150px"
    prepend-inner-icon="mdi-account-details"
    :label="$t('calendar.selectUser')"
    :search-input.sync="search"
    :items="calendarOwnersList"
    :menu-props="{ auto: true }"
    dense
    outlined
    return-object
    hide-details
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    search: null,
  }),

  computed: {
    ...mapGetters({
      calendarOwnersList: 'calendarOwners',
      currentCalendarOwner: 'currentCalendarOwner',
    }),
    calendarOwner: {
      get() {
        return this.currentCalendarOwner;
      },
      set(calendarOwner) {
        this.$store.dispatch('setCurrentCalendarOwner', calendarOwner);
        this.$emit('change', calendarOwner);
      },
    },
  },
};
</script>
