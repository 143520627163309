<template>
  <v-sheet height="900">
    <div class="d-none d-print-block">
      <div v-if="type === 'month'" class="text-h5">
        {{ monthYearFormatted }}
      </div>
      <div v-else class="text-h5">
        {{ dayMonthYearFormatted }}
      </div>
      <div class="text-h6">
        {{ name }}
      </div>
    </div>

    <v-progress-linear
      :indeterminate="isProgressBarActive"
      :active="isProgressBarActive"
      absolute
      color="#0D47A1"
    />

    <v-col justify="center" class="py-2" md="3">
      <calendar-owner-selector
        ref="calendarOwnerSelector"
        class="d-print-none"
        @change="updateCalendarOwnerSelection"
      />
    </v-col>

    <v-calendar
      id="month-calendar"
      ref="calendar"
      v-model="value"
      :style="weekDayStyle"
      :weekdays="daysArrangement"
      :type="type"
      :events="events"
      :interval-format="intervalFormat"
      :event-overlap-mode="mode"
      :event-overlap-threshold="30"
      :event-color="getEventColor"
      :event-text-color="textColor"
      :start="currentDate"
      :now="today"
      :locale="$i18n.locale"
      color="primary"
      @mousedown:event="startDrag"
      @mousedown:day="startDraggingDateRange"
      @mouseenter:day="modifySelectedDates"
      @mouseup:day="dropEvent"
      @mousedown:time="startTime"
      @mouseup:time="endDrag"
      @mousemove:time="mouseMove"
      @click.native="getNativeEvent"
      @click:more="openEventsOnADayCard"
      @mouseup:event="openViewScheduleModal"
      @change="refreshCalendar"
    >
      <template #day-month="{}">
        <v-container class="red"> </v-container>
      </template>
      <template #day-label="{ date, day }">
        <v-container
          class="pa-0 py-1"
          :style="dayHeaderStyle(date)"
          @click.stop="navigateToDate(date)"
        >
          <v-btn x-small depressed :color="dayButtonStyle(date)">
            {{ day }}
            <span class="hidden-sm-and-down">
              {{
                checkNationalHoliday(date) ? getNationalHolidayName(date) : ''
              }}
            </span>
          </v-btn>
        </v-container>
      </template>
      <template #day-label-header="{ date, day }">
        <v-btn block depressed :color="dayButtonStyle(date)">
          {{ day }}
          {{
            checkNationalHoliday(date)
              ? ': ' + getNationalHolidayName(date)
              : ''
          }}
        </v-btn>
      </template>
      <template #interval="interval">
        <v-container
          v-if="isTimeIncludedInDrag(interval)"
          class="pa-0 ma-0 grey lighten-3"
          :style="'height:100%; border-radius:2px;'"
          fluid
        ></v-container>
      </template>
      <template #event="{ event, singline, eventParsed }">
        <v-tooltip
          bottom
          :open-delay="500"
          color="#000000"
          :disabled="$vuetify.breakpoint.xsOnly"
          :open-on-hover="$vuetify.breakpoint.smAndUp"
        >
          <template #activator="{ on }">
            <div
              :class="[
                'pl-1',
                isAttendingStatusNotGoing(event.eventCrude) ||
                isAttendingStatusMaybe(event.eventCrude)
                  ? 'isNotGoingOrMaybe'
                  : '',
              ]"
              :style="getEventChipStyle(event.eventCrude, type)"
              v-on="on"
            >
              <strong
                v-if="type === 'month' && !eventParsed.allDay"
                :class="{
                  'text-decoration-line-through': isAttendingStatusNotGoing(
                    event.eventCrude,
                  ),
                }"
              >
                {{ getTime(eventParsed.start.time, eventParsed.end.time) }}
              </strong>
              <span
                :class="{
                  'text-decoration-line-through': isAttendingStatusNotGoing(
                    event.eventCrude,
                  ),
                }"
              >
                <v-icon v-if="event.eventCrude.priority" x-small>
                  mdi-star
                </v-icon>
                <v-icon
                  v-if="event.eventCrude.isTask && event.eventCrude.is_done"
                  small
                >
                  mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-icon
                  v-if="event.eventCrude.isTask && !event.eventCrude.is_done"
                  small
                >
                  mdi-circle-outline
                </v-icon>
                {{ eventParsed.input.name }}
              </span>
              <span
                v-if="singline && !eventParsed.allDay && $route.name === 'day'"
              >
                {{ ',' }}
              </span>
              <span v-else>
                <br />
              </span>
              <span
                :class="{
                  'text-decoration-line-through': isAttendingStatusNotGoing(
                    event.eventCrude,
                  ),
                }"
              >
                {{ eventParsed.start.time }} ~ {{ eventParsed.end.time }}
              </span>
            </div>
          </template>
          {{ timeDurationString(eventParsed) }}
        </v-tooltip>
      </template>
    </v-calendar>

    <ViewScheduleModal
      ref="viewScheduleModal"
      @close="refreshCalendar"
      @copy="copyToClipboard"
      @open-edit-form="openEditEvent"
    />
    <events-on-a-day-card
      ref="moreEvents"
      :events="eventsForDay"
      :user-calendars="calendars"
      :pos-x="clickX"
      :pos-y="clickY"
      @open-event-details="openViewEventDetails"
      @close="refreshCalendar"
    />
    <AddEditEvent
      @added:event="refreshCalendar"
      @added:meeting="refreshCalendar"
      @added:task="refreshCalendar"
      @updated:event="refreshCalendar"
      @paste="pasteEvent"
    />
    <AddEventQuick
      ref="addEventQuick"
      @added:event="refreshCalendar"
      @added:meeting="refreshCalendar"
      @added:task="refreshCalendar"
      @paste="pasteEvent"
      @open-detailed-form="openAddEditEvent"
    />
  </v-sheet>
</template>

<script>
import { parseISO, format } from 'date-fns';
import { mapGetters, mapActions } from 'vuex';
import EventsOnADayCard from '../components/EventsOnADayCard.vue';
import ViewScheduleModal from '../components/ViewScheduleModal.vue';
import CalendarOwnerSelector from '../components/CalendarOwnerSelector.vue';
import AddEventQuick from '../components/AddEventQuick.vue';
import AddEditEvent from '../components/AddEditEvent.vue';
import dataRefresher from '../mixins/dataRefresher';
import calendarEventChip from '../mixins/calendarEventChip';

const WHITE = '#FFFFFF';
const SATURDAY_COLOR = '#E1F5FE';
const SUNDAY_COLOR = '#FFCDD2';

export default {
  components: {
    EventsOnADayCard,
    ViewScheduleModal,
    AddEventQuick,
    CalendarOwnerSelector,
    AddEditEvent,
  },
  mixins: [dataRefresher, calendarEventChip],

  beforeRouteLeave(from, to, next) {
    this.$store.dispatch('resetAddEditEventFormState');
    next();
  },

  data() {
    return {
      isProgressBarActive: null,
      type: null,
      mode: 'column',
      value: '',
      calendars: [],
      eventsCrude: [],
      eventsForDay: [],
      moreEventFired: false,
      eventMove: null,
      eventCopy: null,
      events: [],
      timeRange: null,
      initialDate: null,
      clickX: null,
      clickY: null,
      schedule: null,
      userCalendars: null,
      error: false,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      names: [
        'Meeting',
        'Holiday',
        'PTO',
        'Travel',
        'Event',
        'Birthday',
        'Conference',
        'Party',
      ],
      draggingData: {
        isDragging: false,
        hasTime: false,
        initialClickDate: null,
        initialDragTime: null,
        finalDragTime: null,
        datesInDragPath: [],
      },
      dragEvent: null,
      dragTime: null,
      extendOriginal: null,
      isDraggingInDayView: false,
    };
  },

  computed: {
    ...mapGetters({
      user: 'user',
      id: 'id',
      name: 'name',
      department: 'department',
      eventOnClipboard: 'eventOnClipboard',
      isNationalHoliday: 'isNationalHoliday',
      userData: 'userData',
      currentDate: 'currentDate',
      calendarOwner: 'currentCalendarOwner',
      firstDayOfTheWeek: 'firstDayOfTheWeek',
      preferences: 'preferences',
    }),
    today() {
      return App.helpers.getDateOfToday();
    },
    weekDayStyle() {
      let day1Color = '';
      let day6Color = SATURDAY_COLOR;
      let day7Color = SUNDAY_COLOR;

      if (this.firstDayOfTheWeek === 'sunday') {
        day1Color = SUNDAY_COLOR;
        day6Color = '';
        day7Color = SATURDAY_COLOR;
      }
      return {
        '--day-1-color': day1Color,
        '--day-2-color': WHITE,
        '--day-3-color': WHITE,
        '--day-4-color': WHITE,
        '--day-5-color': WHITE,
        '--day-6-color': day6Color,
        '--day-7-color': day7Color,
      };
    },
    daysArrangement() {
      if (this.firstDayOfTheWeek === 'sunday') {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5, 6, 0];
    },
    monthYearFormatted() {
      const date = parseISO(this.currentDate);
      return format(date, 'yyyy年MM月');
    },
    dayMonthYearFormatted() {
      const date = parseISO(this.currentDate);
      return format(date, 'yyyy年MM月dd日');
    },
  },

  watch: {
    currentDate(newVal) {
      this.isProgressBarActive = true;
      this.type = this.$route.name;
      this.getEventsForCalendarOwner(this.calendarOwner);
    },
    $route(to, from) {
      this.type = this.$route.name;
    },
  },

  created() {
    let { calendarOwner } = this;
    if (!calendarOwner) {
      calendarOwner = this.user;
    }

    this.isProgressBarActive = true;
    this.type = this.$route.name;
    this.getEventsForCalendarOwner(calendarOwner);
    this.initialDate = this.currentDate;
    this.$root.$on('update-user-calendar', (user) =>
      this.getEventsForCalendarOwner(calendarOwner),
    );
  },

  mounted() {
    this.$refs.calendar.scrollToTime('09:00');
    this.setUpRefresher(this.getEvents);
  },

  methods: {
    ...mapActions(['setEventOnClipboard', 'openSnackbar']),

    updateCalendarOwnerSelection(calendarOwner) {
      this.$store.dispatch('setCurrentCalendarOwner', calendarOwner);
      this.getEventsForCalendarOwner(calendarOwner);
    },

    getEvents() {
      this.getEventsForCalendarOwner(this.calendarOwner);
    },

    getEventsForCalendarOwner(calendarOwner) {
      // TODO: add conditions based on owner type.
      // axios
      //   .get(`api/users/${}/calendars`)
      //   .then((response) => {
      //     this.calendars = response.data.calendars;
      //   })
      //   .catch(() => {});
      this.calendars = calendarOwner.calendars;
      this.getAllEventsFromUserCalendars();
    },
    async getAllEventsFromUserCalendars() {
      const start = App.helpers.getISODateString(
        App.helpers
          .getDateObject(this.currentDate)
          .setMonth(App.helpers.getDateObject(this.currentDate).getMonth() - 2),
      );
      const end = App.helpers.getISODateString(
        App.helpers
          .getDateObject(this.currentDate)
          .setMonth(App.helpers.getDateObject(this.currentDate).getMonth() + 1),
      );
      const eventsCrude = await this.getEventsForDates(
        this.calendars,
        start,
        end,
      );
      this.parseEventInVCalFormat(eventsCrude);
    },

    intervalFormat(interval) {
      return interval.time;
    },
    dayHeaderStyle(date) {
      if (!this.isDateIncludedInDrag(date)) {
        return '';
      }
      let style =
        'background: #BBDEFD; border-top: 1px solid; border-bottom: 1px solid;';
      const { length } = this.draggingData.datesInDragPath;
      if (date === this.draggingData.datesInDragPath[0]) {
        style += 'border-left: 1px solid;';
      }
      if (date === this.draggingData.datesInDragPath[length - 1]) {
        style += 'border-right: 1px solid;';
      }
      return style;
    },

    dayButtonStyle(date) {
      if (this.today === date) {
        return 'primary';
      }
      if (
        this.checkNationalHoliday(date) ||
        App.helpers.getDateObject(date).getDay() === 0
      ) {
        return '#FFCDD2';
      }

      if (App.helpers.getDateObject(date).getDay() === 6) {
        return '#E1F5FE';
      }

      return 'white';
    },

    isDateIncludedInDrag(dateToBeChecked) {
      return this.draggingData.datesInDragPath.find(
        (date) => date === dateToBeChecked,
      );
    },
    isTimeIncludedInDrag(interval) {
      const time = this.roundTime(this.toTime(interval));
      if (
        time >= this.draggingData.initialDragTime &&
        time <= this.draggingData.finalDragTime
      ) {
        return true;
      }
      return false;
    },
    toTime(intervalData) {
      return new Date(
        intervalData.year,
        intervalData.month - 1,
        intervalData.day,
        intervalData.hour,
        intervalData.minute,
      ).getTime();
    },
    roundTime(time, down = true, roundTo = 15) {
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    startDraggingDateRange(event) {
      Object.assign(this.$data.draggingData, this.$options.data().draggingData);
      this.draggingData.isDragging = true;
      this.draggingData.initialClickDate = event.date;
    },
    startTime(event) {
      const mouse = this.toTime(event);
      if (this.dragEvent && this.dragTime === null) {
        let { start } = this.dragEvent.eventCrude;
        start = App.helpers.getDateObject(start);
        this.dragTime = mouse - start;
      } else {
        Object.assign(
          this.$data.draggingData,
          this.$options.data().draggingData,
        );
        this.draggingData.isDragging = true;
        this.draggingData.hasTime = event.hasTime;
        this.draggingData.initialClickDate = event.date;
        this.draggingData.initialDragTime = this.roundTime(
          this.toTime(event),
          true,
          60,
        );
      }
    },
    modifySelectedDates(event) {
      if (this.draggingData.isDragging === false) {
        return;
      }
      const currentDragDate = event.date;
      this.draggingData.datesInDragPath = this.getDaysInDragPath(
        this.draggingData.initialClickDate,
        currentDragDate,
      );
    },
    getDaysInDragPath(initialDate, currentDate) {
      let startDate = initialDate < currentDate ? initialDate : currentDate;
      let endDate = initialDate < currentDate ? currentDate : initialDate;
      startDate = App.helpers.getDateObject(startDate);
      endDate = App.helpers.getDateObject(endDate);
      const datesInDragPath = [];
      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        datesInDragPath.push(App.helpers.getISODateString(date));
      }
      return datesInDragPath;
    },
    mouseMove(event) {
      const mouse = this.toTime(event);
      if (this.dragEvent && this.dragTime !== null) {
        this.isDraggingInDayView = true;
        let { start } = this.dragEvent.eventCrude;
        let { end } = this.dragEvent.eventCrude;
        start = App.helpers.getDateObject(start);
        end = App.helpers.getDateObject(end);
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = this.formatDate(
          App.helpers.getDateObject(newStart),
          '0',
        );
        this.dragEvent.end = this.formatDate(
          App.helpers.getDateObject(newEnd),
          '0',
        );
      }

      if (this.draggingData.isDragging === false) {
        return;
      }
      const currentTime = this.roundTime(this.toTime(event));
      this.getTimesInDragPath(this.draggingData.initialDragTime, currentTime);
    },
    getTimesInDragPath(initialTime, currentTime) {
      this.draggingData.initialDragTime =
        initialTime < currentTime ? initialTime : currentTime;
      this.draggingData.finalDragTime =
        initialTime < currentTime ? currentTime : initialTime;
    },
    endDragForDateRange(event, nativeEvent) {
      if (this.draggingData.isDragging === false) {
        return;
      }
      this.draggingData.isDragging = false;
      this.openAddForm(event, this.draggingData.initialClickDate);
    },
    endDrag(event) {
      if (this.isDraggingInDayView === true) {
        const eventToBeMoved = this.dragEvent.eventCrude;
        const { calendarOwner } = this;
        const newStartDate = this.dragEvent.start.slice(0, 10);
        const newStartTime = this.dragEvent.start.slice(11, 16);
        const newEndDate = this.dragEvent.end.slice(0, 10);
        const newEndTime = this.dragEvent.end.slice(11, 16);
        if (eventToBeMoved.user_id) {
          axios
            .put(`api/task/${eventToBeMoved.id}/move`, {
              id: calendarOwner.id,
              type: calendarOwner.type,
              newStartDate,
              newStartTime,
              newEndDate,
              newEndTime,
            })
            .then(() => {
              this.getEventsForCalendarOwner(calendarOwner);
            });
        } else if (eventToBeMoved.recurrence_id) {
          axios
            .put(`api/event-recur/${eventToBeMoved.recurrence_id}/move`, {
              id: calendarOwner.id,
              type: calendarOwner.type,
              newStartDate,
              newStartTime,
              newEndDate,
              newEndTime,
              edit_type: 1,
            })
            .then(() => {
              this.getEventsForCalendarOwner(calendarOwner);
            });
        } else {
          axios
            .put(`api/event/${eventToBeMoved.id}/move`, {
              id: calendarOwner.id,
              type: calendarOwner.type,
              newStartDate,
              newStartTime,
              newEndDate,
              newEndTime,
            })
            .then(() => {
              this.getEventsForCalendarOwner(calendarOwner);
            });
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.isDraggingInDayView = false;

      if (this.draggingData.isDragging === false) {
        return;
      }
      this.draggingData.isDragging = false;

      this.openAddForm(
        event,
        this.draggingData.initialClickDate,
        this.draggingData.initialDragTime,
      );
    },
    navigateToDate(date) {
      this.$store.dispatch('setCurrentDate', date);
      this.$router.push({
        name: 'day',
      });
    },
    getNationalHolidayName(date) {
      return this.isNationalHoliday(date).name;
    },
    checkNationalHoliday(date) {
      if (this.isNationalHoliday(date) !== undefined) {
        return true;
      }
      return false;
    },

    startDrag(event) {
      if (this.type === 'day') {
        const eventSchedule = event.event;
        if (eventSchedule && event.timed) {
          this.dragEvent = eventSchedule;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      } else {
        this.eventMove = event;
        event.nativeEvent.stopPropagation();
      }
    },
    dropEvent(event) {
      if (this.eventMove == null) {
        this.endDragForDateRange(event);
      } else {
        if (this.eventMove.event.eventCrude.meeting_id) {
          this.openSnackbar({
            color: 'error',
            message: this.$t('common.cannotMoveMeetings'),
          });
          return;
        }
        const eventToBeMoved = this.eventMove.event.eventCrude;
        if (this.eventMove.day.date != event.date) {
          const { calendarOwner } = this;
          const oldEndDate = App.helpers.getDateObject(
            eventToBeMoved.end.split(' ')[0],
          );
          const oldStartDate = App.helpers.getDateObject(
            eventToBeMoved.start.split(' ')[0],
          );
          const diff = oldEndDate.getTime() - oldStartDate.getTime();
          const newStartDate = event.date;
          const nsdT = App.helpers.getDateObject(newStartDate); // remove while refactoring
          const temp = nsdT.getTime() + diff;
          const arg = App.helpers.getDateObject(temp);
          const newEndDate = App.helpers.getISODateString(arg);
          if (eventToBeMoved.meeting_id) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.cannotMoveMeetings'),
            });
            return;
          }
          if (eventToBeMoved.isTask) {
            axios
              .put(`api/task/${eventToBeMoved.id}/move`, {
                id: calendarOwner.id,
                type: calendarOwner.type,
                newStartDate,
                newEndDate,
              })
              .then(() => {
                this.eventMove = null;
                this.getEventsForCalendarOwner(calendarOwner);
              })
              .catch(() => {
                this.$store.dispatch('openSnackbar', {
                  color: 'error',
                  message: this.$t('common.somethingWentWrong'),
                });
              });
            return;
          }
          if (eventToBeMoved.recurrence_id) {
            axios
              .put(`api/event-recur/${eventToBeMoved.recurrence_id}/move`, {
                id: calendarOwner.id,
                type: calendarOwner.type,
                newStartDate,
                newEndDate,
                edit_type: 1,
              })
              .then(() => {
                this.getEventsForCalendarOwner(calendarOwner);
              })
              .catch(() => {
                this.$store.dispatch('openSnackbar', {
                  color: 'error',
                  message: this.$t('event.cannotAdd'),
                });
              });
          } else {
            axios
              .put(`api/event/${eventToBeMoved.id}/move`, {
                id: calendarOwner.id,
                type: calendarOwner.type,
                newStartDate,
                newEndDate,
              })
              .then(() => {
                this.getEventsForCalendarOwner(calendarOwner);
              })
              .catch(() => {
                this.$store.dispatch('openSnackbar', {
                  color: 'error',
                  message: this.$t('event.cannotAdd'),
                });
              });
          }
          this.eventMove = null;
        }
      }
    },
    refreshCalendar(response) {
      if (response !== null) {
        this.getEventsForCalendarOwner(this.calendarOwner);
      }
      Object.assign(this.$data.draggingData, this.$options.data().draggingData);
      this.$store.dispatch('resetAddEditEventFormState');
    },
    openViewScheduleModal({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      if (this.isDraggingInDayView === true) {
        return;
      }
      this.$store.dispatch('resetAddEditEventFormState');
      if (event.eventCrude.subject === undefined) {
        event.eventCrude.subject = undefined; // forcibly update subject as undefined in the store event, to mark as restricted
      }
      this.$store.dispatch('updateEvent', event.eventCrude);
      const calendarOptions = this.filterCalendarsBasedOnPermission(
        this.calendars,
      );
      const formExtras = {
        calendars: calendarOptions,
        formDates: {
          start: event.eventCrude.start,
          end: event.eventCrude.end,
        },
      };
      this.$store.dispatch('updateFormExtras', { ...formExtras });
      const menuPosition = {
        x: nativeEvent.clientX,
        y: nativeEvent.clientY,
      };
      this.$store.dispatch('updateEventMenuPosition', { ...menuPosition });
      setTimeout(() => {
        this.$store.dispatch('openViewScheduleDetailsMenu');
      }, 10);
      this.eventMove = null;
    },

    getNativeEvent(event) {
      this.clickX = event.clientX;
      this.clickY = event.clientY;
      const menuPosition = {
        x: this.clickX,
        y: this.clickY,
      };
      this.$store.dispatch('updateEventMenuPosition', { ...menuPosition });
    },
    openAddForm(event, initialClickDate = null, initialDragTime = null) {
      this.$store.dispatch('resetEventData');
      this.$store.dispatch('resetFormExtras');
      if (this.moreEventFired == true) {
        this.$refs.moreEvents.closeDialog();
        this.moreEventFired = false;
        return;
      }
      // this.$store.dispatch('resetAddEditEventFormState');
      const calendarOptions = this.filterCalendarsBasedOnPermission(
        this.calendars,
      );
      const calendar = this.calendarOwner.default_calendar;
      const calendarId = calendar.id;
      const userInfo = this.calendarOwner;
      if (!this.$root.canLoggedInUserWriteToUserCalendar(userInfo, calendar)) {
        this.openSnackbar({
          color: 'error',
          message: this.$t('event.cannotAdd'),
        });
        return;
      }
      // this.$refs.addEventQuick.setDefaultCalendar(calendarId);
      if (initialClickDate === null) {
        initialClickDate = event.date;
      }
      const finalClickDate = event.date;
      const dateRange =
        initialClickDate < finalClickDate
          ? [initialClickDate, finalClickDate]
          : [finalClickDate, initialClickDate];

      let startTime;
      let endTime;
      if (event.hasTime) {
        if (initialDragTime === null) {
          initialDragTime = this.roundTime(this.toTime(event));
        }
        const finalClickTime = this.roundTime(this.toTime(event), false, 60);
        startTime =
          finalClickTime > initialDragTime ? initialDragTime : finalClickTime;
        endTime =
          finalClickTime > initialDragTime ? finalClickTime : initialDragTime;
        // const modifiedEndTime = parseInt(endTime.substring(0, 2), 10) + 1;
        // if (modifiedEndTime > 9) {
        //   endTime = `${modifiedEndTime}:00`;
        // } else {
        //   endTime = `0${modifiedEndTime}:00`;
        // }
        let temp = App.helpers.getDateObject(startTime);
        startTime = `${`0${temp.getHours()}`.slice(
          -2,
        )}:${`0${temp.getMinutes()}`.slice(-2)}`;
        temp = App.helpers.getDateObject(endTime);
        endTime = `${`0${temp.getHours()}`.slice(
          -2,
        )}:${`0${temp.getMinutes()}`.slice(-2)}`;
      }
      const formExtras = {
        calendars: calendarOptions,
        defaultCalendar: calendarId,
        formDates: {
          start: `${dateRange[0]} 10:00`,
          end: `${dateRange[1]} 11:00`,
        },
        timeRange: [startTime, endTime],
        hasTime: event.hasTime,
        owner: {
          id: this.calendarOwner.id,
          type: this.calendarOwner.type,
        },
      };
      this.$store.dispatch('updateFormExtras', { ...formExtras });
      if (this.$vuetify.breakpoint.smAndUp) {
        this.$store.dispatch('openAddEventQuick');
      } else {
        this.$store.dispatch('openAddEditEventSheet');
      }
    },
    filterCalendarsBasedOnPermission(calendars) {
      return calendars.filter((calendar) => {
        if (calendar.user_id == this.id) return true;
        if (
          calendar.my_department == 'private' &&
          calendar.other_department == 'private'
        ) {
          return false;
        }
        return true;
      });
    },
    getDefaultUserCalendar(id) {
      return axios
        .get(`api/calendar/default/user/${id}`)
        .then((response) => response.data);
    },
    openEventsOnADayCard(event) {
      this.$store.dispatch('closeAddEventQuick');
      this.eventsForDay = this.$refs.calendar.getEventsForDay(event);
      this.$refs.moreEvents.openDialog();
      this.moreEventFired = true;
    },

    getEventsForDates(calendars, start, end) {
      this.isProgressBarActive = true;
      const dates = [start, end];
      const calendarIDs = this.getCalendarIds(calendars);
      return axios
        .get(
          `api/events/${JSON.stringify(calendarIDs)}/${JSON.stringify(dates)}`,
        )
        .then((response) => response.data.events);
    },
    getCalendarIds(calendars) {
      const calIds = [];
      calendars.forEach((calendar) => {
        calIds.push(calendar.id);
      });
      return calIds;
    },
    parseEventInVCalFormat(eventsCrude) {
      const events = [];
      eventsCrude.forEach((eventCrude) => {
        const startDate = App.helpers.getDateObject(eventCrude.start);
        const endDate = App.helpers.getDateObject(eventCrude.end);
        let name;
        if (eventCrude.subject) {
          name = eventCrude.subject;
        } else if (eventCrude.subject !== null) {
          name = eventCrude.all_day
            ? this.$t('event.busyAllDay')
            : this.$t('event.busy');
        } else {
          name = this.getEventType(eventCrude.event_type_id).id
            ? `(${this.getEventType(eventCrude.event_type_id).name})`
            : `(${this.$t('event.noSubject')})`;
        }
        const { color } = this.getEventType(eventCrude.event_type_id);
        const textColor = this.getTextColor(eventCrude.event_type_id);
        const start = this.formatDate(startDate, eventCrude.all_day);
        const end = this.formatDate(endDate, eventCrude.all_day);

        events.push({ name, color, textColor, start, end, eventCrude });
      });
      this.events = events;
      this.isProgressBarActive = false;
    },
    getTextColor(eventType) {
      if (this.normalizedEventTypes[eventType]) {
        return this.$root.getTextColor(
          this.normalizedEventTypes[eventType].color,
        );
      }
      return '';
    },
    getEventColor(event) {
      return event.color;
    },
    textColor(event) {
      return event.textColor;
    },
    formatDate(a, withTime) {
      const year = a.getFullYear();

      let month = a.getMonth() + 1;
      month = `0${month}`.slice(-2);

      let date = a.getDate();
      date = `0${date}`.slice(-2);

      let hours = a.getHours();
      hours = `0${hours}`.slice(-2);

      let minutes = a.getMinutes();
      minutes = `0${minutes}`.slice(-2);

      return withTime == '0'
        ? `${year}-${month}-${date} ${hours}:${minutes}`
        : `${year}-${month}-${date}`;
    },
    copyToClipboard(schedule) {
      this.setEventOnClipboard(schedule);
    },
    pasteEvent(calendarId, date) {
      const { calendarOwner } = this;
      if (this.eventOnClipboard.isTask && calendarOwner.type === 'user') {
        axios
          .post(`api/task/${this.eventOnClipboard.id}/copy`, {
            calendarId,
            date,
          })
          .then((response) => {
            this.getEventsForCalendarOwner(calendarOwner);
          })
          .catch((error) => {
            if (
              error.response.data &&
              error.response.data.error === 'cannot-update-private'
            ) {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t(`common.${error.response.data.messageCode}`),
              });
              this.getEventsForCalendarOwner(calendarOwner);
              return;
            }
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.cannotCopyTask'),
            });
          });
        return;
      }
      if (this.eventOnClipboard.meeting_id) {
        this.openEditMeetingPage(this.eventOnClipboard.meeting_id);
      }
      if (this.eventOnClipboard.recurrence_id) {
        axios
          .post(`api/event-recur/${this.eventOnClipboard.recurrence_id}/copy`, {
            calendar_id: calendarId,
            edit_type: 1,
            date,
          })
          .then(() => {
            this.getEventsForCalendarOwner(calendarOwner);
          })
          .catch((error) => {
            if (
              error.response.data &&
              error.response.data.error === 'cannot-update-private'
            ) {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t(`common.${error.response.data.messageCode}`),
              });
              this.getEventsForCalendarOwner(calendarOwner);
            }
          });
      } else {
        axios
          .post(`api/event/${this.eventOnClipboard.id}/copy`, {
            calendar_id: calendarId,
            date,
          })
          .then(() => {
            this.getEventsForCalendarOwner(calendarOwner);
          })
          .catch((error) => {
            if (
              error.response.data &&
              error.response.data.error === 'cannot-update-private'
            ) {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t(`common.${error.response.data.messageCode}`),
              });
              this.getEventsForCalendarOwner(calendarOwner);
            }
          });
      }
    },
    openAddEditEvent(data) {
      const formExtras = {
        formDates: data.dateTimeRange,
        defaultCalendar: data.form.calendar_id,
        owner: {
          id: this.calendarOwner.id,
          type: this.calendarOwner.type,
        },
      };
      this.$store.dispatch('updateFormExtras', formExtras);
      this.$store.dispatch('updateEvent', data.form);
      this.$store.dispatch('openAddEditEventSheet');
    },
    openEditEvent(data) {
      const formExtras = {
        formDates: data.dateTimeRange,
        defaultCalendar: data.defaultCalendar,
        isEditing: true,
        owner: {
          id: this.calendarOwner.id,
          type: this.calendarOwner.type,
        },
      };
      this.$store.dispatch('updateFormExtras', formExtras);
      data.form.start = data.dateTimeRange.start;
      data.form.end = data.dateTimeRange.end;
      this.$store.dispatch('updateEvent', data.form);
      this.$store.dispatch('openAddEditEventSheet');
    },
    openViewEventDetails(schedule) {
      this.$store.dispatch('updateEvent', schedule);
      const calendarOptions = this.filterCalendarsBasedOnPermission(
        this.calendars,
      );
      const formExtras = {
        calendars: calendarOptions,
        formDates: {
          start: schedule.start,
          end: schedule.end,
        },
      };
      this.$store.dispatch('updateFormExtras', { ...formExtras });
      setTimeout(() => {
        this.$store.dispatch('openViewScheduleDetailsMenu');
      }, 10);
      this.eventMove = null;
    },
    timeDurationString(schedule) {
      const start = `${schedule.start.date} ${schedule.start.time}`;
      const end = `${schedule.end.date} ${schedule.end.time}`;
      let timeString;
      if (schedule.allDay) {
        timeString = this.$i18n.d(
          App.helpers.getDateObject(start),
          'allDayEvent',
        );
      } else {
        timeString = `${this.$i18n.d(
          App.helpers.getDateObject(start),
          'eventTime',
        )} ~ ${this.$i18n.d(App.helpers.getDateObject(end), 'eventTime')}`;
      }
      return timeString;
    },
    getTime(startTime, endTime) {
      if (this.preferences.is_end_time_shown) {
        return `${startTime}~${endTime}`;
      }
      return startTime;
    },
  },
};
</script>

<style>
.isNotGoingOrMaybe {
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px var(--shadow-color);
}

.v-calendar-weekly__week > :nth-child(1) {
  background-color: var(--day-1-color);
}
.v-calendar-weekly__week > :nth-child(2) {
  background-color: var(--day-2-color);
}
.v-calendar-weekly__week > :nth-child(3) {
  background-color: var(--day-3-color);
}
.v-calendar-weekly__week > :nth-child(4) {
  background-color: var(--day-4-color);
}
.v-calendar-weekly__week > :nth-child(5) {
  background-color: var(--day-5-color);
}
.v-calendar-weekly__week > :nth-child(6) {
  background-color: var(--day-6-color);
}
.v-calendar-weekly__week > :nth-child(7) {
  background-color: var(--day-7-color);
}
</style>
